import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"


export default makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 0)
    }
  },

  treeContainer: {
    flexDirection: "column",
    margin: theme.spacing(4, 0)
  },

  upperCards: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },

  middleCards: {},

  lowerCards: {},

  tallCardContainer: {
    padding: theme.spacing(0, 0.5)
  },

  tallCard: {
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(2),
    borderRadius: theme.roundness * 2,
    cursor: "help",
    "& p": {
      color: theme.palette.text.lightText,
      textAlign: "center",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body2.fontSize
    }
  },

  longCard: {
    padding: theme.spacing(2),
    borderRadius: theme.roundness * 2,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
      cursor: "default"
    },
    "& p": {
      color: theme.palette.text.lightText,
      textAlign: "center",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body2.fontSize
    }
  },

  longCardSingle: {
    width: "100%",
    margin: theme.spacing(0)
  },

  divider: {
    width: "75%",
    marginLeft: "auto",
    marginRight: "auto",
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.text.lightText
  },

  verticalConnector: {
    height: theme.spacing(4),
    borderRight: `1px ${ theme.palette.primary.main } solid`
  },

  horizontalConnector: {

    [theme.breakpoints.up("md")]: {
      borderBottom: `1px ${ theme.palette.primary.main } solid`,
    },
  },

  topConnector: {

    [theme.breakpoints.up("md")]: {
      borderTop: `1px ${ theme.palette.primary.main } solid`,
    },
  },

  middleCardsRightContainer: {
    [theme.breakpoints.up("md")]: {
      borderLeft: `1px ${ theme.palette.primary.main } solid`,
    },
  },

  middleCardsLeftContainer: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(4),
    },
  },

  instructionsContainer: {
    visibility: 'hidden',
    [theme.breakpoints.up("md")]: {
      visibility: 'visible',
      margin: theme.spacing(4, 0),
    },
  }
}))
