import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"
import { Grid, Typography } from "@material-ui/core/"
import * as React from "react"
import { FC } from "react"
import theme from "../../../App.theme"
import { SectionContainer, SectionTitle, SectionText } from "../../_layout"
import { MdExpandMore } from "react-icons/md"

import useStyles from "./styles"

export interface IAspectosContent {
  titulo: string
  contenido: string
  orden: number
}

interface IAspectosDestacados {
  title: string
  sectionID: string
  aspectos: IAspectosContent[]
}

const AspectosDestacados: FC<IAspectosDestacados> = ({ title, aspectos, sectionID }) => {
  const styles = useStyles()

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle { ...{ title } } />
      <SectionText text={ "Da clic en cada elemento para ver más información." } />
      <Grid container spacing={ 1 } className={ styles.root }>
        {
          aspectos.map((aspecto, i) => (
            <Grid key={ i } item xs={ 12 } md={ 6 } className={ styles.accordionItem }>
              <Accordion classes={ { root: styles.accordion } }>
                <AccordionSummary
                  classes={ { root: styles.summary } }
                  expandIcon={ <MdExpandMore /> }
                  aria-controls={ `panel${ i }-content` }
                  id={ `panel${ i }-header` }
                >
                  <Grid container alignItems={ "center" }>
                    <Grid item>
                      <div className={ styles.circle }>
                        {/*<Typography>{ aspecto.orden }</Typography>*/}
                      </div>
                    </Grid>
                    <Grid item xs>
                      <Typography className={ styles.heading }>{ aspecto.titulo }</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    { aspecto.contenido }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))
        }
      </Grid>
    </SectionContainer>
  )
}

export default AspectosDestacados
