import * as React from "react"
import { ReactElement } from "react"
import { AiOutlineWoman } from "react-icons/ai"
import { GiSchoolBag, GiField } from "react-icons/gi"
import { BiStore } from "react-icons/bi"
import { BsFillPeopleFill } from "react-icons/bs"
import { FaAmericanSignLanguageInterpreting } from "react-icons/fa"

interface IBeneficios {
  titulo: string
  bullets: string[]
  icon: ReactElement
}

export const beneficiosDeLaPolitica: IBeneficios[] = [
  {
    titulo: "Mujeres",
    icon: <AiOutlineWoman />,
    bullets: [
      "Las mujeres podrán contar con programas y productos de crédito acordes a sus necesidades financieras, a" +
      " partir de las acciones de la banca de desarrollo.",
      "Las mujeres verán un incremento en los puestos directivos ocupados por ellas, a partir de la visibilización" +
      " de las brechas en este tipo de puestos en las instituciones financieras.",
      "Existirá un mayor número de propuestas para erradicar las barreras que impiden una mayor inclusión financiera de las mujeres, a partir del fomento a la investigación en las universidades."
    ]
  },
  {
    titulo: "Niñas, niños, adolecentes y estudiantes",
    icon: <GiSchoolBag />,
    bullets: [
      "La incorporación de educación financiera en las escuelas permitirá que las niñas, niños y adolescentes" +
      " cuenten con mejores herramientas para un adecuado manejo de sus finanzas personales, los principales conceptos financieros y el inicio del hábito de ahorrar.",
      "Los conceptos financieros serán llevados de forma entretenida e interactiva a sitios como museos.",
      "Se transmitirá la importancia de contar con un seguro para hacer frente a las emergencias económicas y de una" +
      " Afore para garantizar una vejez digna.",
      "Los estudiantes encontraran espacios para expresar sus propuestas para reducir la exclusión financiera, a" +
      " partir de premios de investigación entre estudiantes."
    ]
  },
  {
    titulo: "Personas adultas mayores y población indígena",
    icon: <FaAmericanSignLanguageInterpreting />,
    bullets: [
      "Las personas adultas mayores encontrarán un sistema financiero más acorde a sus necesidades, a partir de un" +
      " mayor número de cajeros y sucursales adaptados a su edad.",
      "Se expandirá la red de puntos de acceso físicos y digitales en regiones habitadas por personas indígenas.",
      "Las personas indígenas encontrarán un sistema financiero que les entregue información sobre los productos financieros en su lengua, a partir de la existencia de contratos de adhesión en lengua indígena."
    ]
  },
  {
    titulo: "Beneficiarios de los programas sociales",
    icon: <GiField />,
    bullets: [
      "Los beneficiarios de los programas sociales recibirán información económico-financiera, a través de cursos," +
      " que les ayudará a tomar mejores decisiones sobre el dinero que reciben.",
      "A los beneficiarios se les impulsará a que comiencen a formar un ahorro que les permita vivir dignamente su" +
      " retiro, a partir de vincular la dispersión con un Afore o una cuenta de ahorro.",
      "Asimismo, se buscará que los beneficiarios cuenten con un seguro de vida.",
      "Gracias al desarrollo del ecosistema de pagos digitales, los beneficiarios de los programas sociales encontraran más lugares dónde usar medios de pago electrónico."
    ]
  },
  {
    titulo: "Dueños de micro y pequeñas empresas",
    icon: <BiStore />,
    bullets: [
      "Las dueñas y los dueños de las micro y pequeñas empresas contarán con mayor crédito, a partir de las acciones" +
      " de la banca de desarrollo, la banca comercial, las EACP y el crecimiento del sector Fintech.",
      "La expansión del CoDi y otros medios de pago incrementará las ofertas de servicios financieros cuando lo" +
      " necesiten.",
      "A su vez, se buscará incrementar el financiamiento a empresas a través de nuevos esquemas de garantías."

    ]
  },
  {
    titulo: "Toda la población",
    icon: <BsFillPeopleFill />,
    bullets: [
      "La población tendrá a su disposición herramientas que le permitan tomar una mejor decisión sobre la" +
      " adquisición de un producto financiero, a partir de compradores amigables y útiles.",
      "La población tendrá una mayor y mejor oferta de crédito e instrumentos de pago, a partir de la expansión de" +
      " las empresas e instituciones de tecnología financiera (Fintech).",
      "Las personas encontrarán un mayor número de comercios, medios de transportes, y lugares habilitados para" +
      " recibir pagos digitales.",
      "Las personas podrán usar dispositivos digitales para realizar operaciones financieras."
    ]
  }
]