import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  root: {
    margin: theme.spacing(0, 0),
    [theme.breakpoints.up("md")]: {}
  },

  accordion: {
    boxShadow: theme.shadows[0],
    margin: theme.spacing(0.5, 0),
    width: "100%"
  },

  accordionItem: {},

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },

  circle: {
    // width: 38,
    // height: 38,
    width: 18,
    height: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
    borderRadius: theme.roundness,
    marginRight: theme.spacing(2),
    "& p": {
      color: theme.palette.text.lightText,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h6.fontSize
    }
  },

  summary: {
    padding: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4)
    }

  }
}))
