import theme from "../App.theme"

export const cardsText = {
  upperCards: [
    {
      id: 1,
      text: "Alta incidencia de pobreza y desigualdad",
      color: theme.palette.primary.main
    },
    {
      id: 2,
      text: "Limitada salud financiera",
      color: theme.palette.primary.main
    },
    {
      id: 3,
      text: "Exclusión financiera",
      color: theme.palette.extras.orange
    }
  ],

  middleCard: "Insuficiente información e investigación para detectar barreras y áreas de oportunidad",

  lowerCards: [
    {
      id: 1,
      text: "Baja tenencia y bajo uso de servicios financieros",
      tootltip: "1.1. Insuficientes acciones de inclusión financiera de ola banca de desarrollo e instituciones de fomento\n" +
        "1.2. Baja competencia en el sistema financiero\n" +
        "1.3. Baja densidad en la tenencia de productos financieros\n" +
        "1.4. Baja movilidad en los servicios financieros",
      color: theme.palette.primary.main
    },
    {
      id: 2,
      text: "Escaso uso de pagos digitales",
      tootltip: "2.1. Ecosistema de pagos digitales poco desarrollado\n" +
        "2.2. Preponderante uso del efectivo\n" +
        "2.3. Escasa competencia en el mercado de pagos",
      color: theme.palette.extras.orange
    },
    {
      id: 3,
      text: "Insuficiente infraestructura para el acceso y provisión de productos y servicios financieros",
      tootltip: "3.1. Insuficiente cobertura física y digital del sistema financiero\n" +
        "3.2. Diversos sistemas de validación y autenticación de la identidad\n" +
        "3.3. Insuficientes sistemas de información para la provisión de sistemas financieros\n" +
        "3.4. Limitada cobertura de conectividad",
      color: theme.palette.extras.blueType1
    },
    {
      id: 4,
      text: "Bajas competencias económico-financieras",
      tootltip: "4.1. Insuficiente educación económica-financiera en las escuelas\n" +
        "4.2. Insuficiente capacitación y difusión de educación económica-financiera en las escuelas",
      color: theme.palette.extras.yellow
    },
    {
      id: 5,
      text: "Insuficientes herramientas de información y mecanismos de protección financiera",
      tootltip: "5.1. Escaso uso de las herramientas de comparación y transparencia de los servicios financieros\n" +
        "5.2. Escaso entendimiento de los términos financieros\n" +
        "5.3. Bajo empoderamiento del usuario del sistema financiero\n" +
        "5.4. Desconfianza de los usuarios en el sistema financiero",
      color: theme.palette.extras.olive
    },
    {
      id: 6,
      text: "Escasa inclusión financiera de personas en situación de vulnerabilidad",
      tootltip: "6.1. Baja accesibilidad de grupos vulnerables al sistema financiero\n" +
        "6.2. Escasa resiliencia financiera de la población de bajos ingresos\n" +
        "6.3. Baja inclusión financiera de los migrantes y de sus familias\n" +
        "6.4. Baja inclusión financiera de las mujeres",
      color: theme.palette.extras.blueType2
    }
  ]
}