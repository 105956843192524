import { Grid, Typography } from "@material-ui/core/"
import * as React from "react"
import { FC } from "react"
import theme from "../../../App.theme"
import { beneficiosDeLaPolitica } from "../../../data/beneficios"
import { SectionContainer, SectionTitle } from "../../_layout"

import useStyles from "./styles"

interface IBeneficios {
}

const colors = [
  theme.palette.extras.orange,
  theme.palette.extras.darkOlive
]

const Beneficios: FC<IBeneficios> = () => {
  const styles = useStyles()


  return (
    <SectionContainer sectionID={ "beneficios-section" }>
      <SectionTitle title={ "Beneficios de la política" } />
      <Grid container className={ styles.beneficiosList }>

        {
          beneficiosDeLaPolitica.map((beneficio, i) => (
            <Grid key={ i } container item className={ styles.beneficiosItem } style={ { border: `1px ${colors[i % 2]} solid` } }>
              <Grid item className={ styles.beneficiosItemTitle } style={ { backgroundColor: colors[i % 2] } }>
                <Typography variant={ "h6" }>
                  { beneficio.titulo }
                </Typography>
              </Grid>

              <Grid container item className={ styles.beneficiosItemContent }>
                <Grid item xs md={ 1 } className={ styles.beneficiosItemContentIconContainer }>
                  <div className={ styles.iconCircle } style={ { backgroundColor: colors[i % 2] } }>
                    { beneficio.icon }
                  </div>
                </Grid>
                <Grid item xs className={ styles.bulletContainer }>
                  <ul>
                    {
                      beneficio.bullets.map((bullet, i) => (
                        <li key={ i }>{ bullet }</li>
                      ))
                    }
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          ))
        }

      </Grid>
    </SectionContainer>
  )
}

export default Beneficios
