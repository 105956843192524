import * as React from "react"
import { FC } from "react"
import { SectionContainer, SectionTitle } from "../../_layout"
import Sponsors from "../../homepage/ThanksSection/Sponsors"
import { ISponsorImage } from "../../homepage/ThanksSection/ThanksSection"

import useStyles from "./styles"

interface IAllies {
  sponsors: ISponsorImage[]
  sectionID: string
}

const Allies: FC<IAllies> = ({ sponsors, sectionID }) => {
  const styles = useStyles()

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle title="Aliados" />
      <Sponsors horizontal { ...{ sponsors } } />
    </SectionContainer>
  )
}

export default Allies
