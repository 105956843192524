import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { ISectionContentImage } from "."
import theme from "../App.theme"

import { ImageSection, Layout, TextImageSection } from "../components/_layout"
import DiagnosticoSection from "../components/AcercaModule/DiagnosticoSection/DiagnosticoSection"
import { TargetsSection } from "../components/homepage"
import { ISponsorImage } from "../components/homepage/ThanksSection/ThanksSection"
import { AspectosDestacados, Beneficios } from "../components/PNIFModule"
import Allies from "../components/PNIFModule/Allies/Allies"
import { IAspectosContent } from "../components/PNIFModule/AspectosDestacados/AspectosDestacados"
import { ISectionContent, ISectionContentExtended } from "./acerca"

const getPageData = graphql`
    query {
        sponsorData: allContentfulColaboradoresLogotipo (
            sort: { fields: [ordenAliados], order: ASC }
            filter: { seccionAliados: { eq: true } }
        ) {

            edges {
                node {
                    nombre
                    logotipo {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        construccionData: allContentfulContenidoPnifConstruccion {
            edges {
                node {
                    titulo
                    contenido {
                        raw
                    }
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        diagnosticoData: allContentfulContenidoPnifDiagnostico {
            edges {
                node {
                    titulo
                    intro {
                        intro
                    }
                    notas {
                        notas
                    }
                }
            }
        }

        visionData: allContentfulContenidoPnifVision {
            edges {
                node {
                    titulo
                    contenido {
                        raw
                    }
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        vinculacionData: allContentfulContenidoPnifVincualacion {
            edges {
                node {
                    titulo
                    contenido {
                        raw
                    }
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        estructuraData: allContentfulContenidoPnifEstructura {
            edges {
                node {
                    titulo
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        aspectosData: allContentfulContenidoPnifAspectosDestacados(
            sort: { fields: [orden], order: ASC }
        ) {
            edges {
                node {
                    titulo
                    orden
                    contenido {
                        contenido
                    }
                }
            }
        }
    }
`


const PNIF: React.FC = () => {
  const {
    sponsorData,
    construccionData,
    diagnosticoData,
    vinculacionData,
    estructuraData,
    aspectosData,
    visionData
  } = useStaticQuery(getPageData)

  const sponsors: ISponsorImage[] = sponsorData.edges.map((e: any) => ({
    nombre: e.node.nombre,
    image: e.node.logotipo.fluid,
    alt: e.node.nombre.split(" ").join("-").toLowerCase()
  }))

  const diagnostico: ISectionContentExtended = diagnosticoData.edges.map(({ node }: any) => ({
    titulo: node.titulo,
    intro: node.intro.intro,
    notas: node.notas.notas
  }))[0]

  const vinculacion: ISectionContentImage = vinculacionData.edges.map(
    ({ node }: any) => ({
      ...getSectionData(vinculacionData),
      image: node.imagen.fluid,
      alt: node.titulo.split(" ").join("-").toLowerCase()
    })
  )[0]

  const construccion: ISectionContentImage = construccionData.edges.map(
    ({ node }: any) => ({
      ...getSectionData(construccionData),
      image: node.imagen.fluid,
      alt: node.titulo.split(" ").join("-").toLowerCase()
    })
  )[0]


  const estructura: ISectionContentImage = estructuraData.edges.map(
    ({ node }: any) => ({
      titulo: node.titulo,
      image: node.imagen.fluid,
      alt: node.titulo.split(" ").join("-").toLowerCase()
    })
  )[0]

  const aspectos: IAspectosContent[] = aspectosData.edges.map((e: any) => ({
    titulo: e.node.titulo,
    contenido: e.node.contenido.contenido,
    orden: e.node.orden
  }))

  const vision: ISectionContentImage = visionData.edges.map(
    ({ node }: any) => ({
      ...getSectionData(visionData),
      image: node.imagen.fluid,
      alt: node.titulo.split(" ").join("-").toLowerCase()
    })
  )[0]

  return (
    <Layout
      withNav
      seoTitle="PNIF"
      seoDescription="Objetivos - logros - alianzas"
    >
      <TextImageSection
        imageBG={ theme.palette.extras.blueType2 }
        image={ vision.image }
        title={vision.titulo}
        content={ vision.contenido }
        alt={ vision.alt }
        sectionID="pnif-vision-section"
      />
      <TargetsSection sectionID="pnif-target-section" />
      <DiagnosticoSection
        title={ diagnostico.titulo }
        upperText={diagnostico.intro}
        lowerText={diagnostico.notas}
        sectionID="pnif-diagnostico-section"
      />
      <TextImageSection
        imageBG={ theme.palette.extras.blueType1 }
        image={ construccion.image }
        title="Construcción de la PNIF"
        content={ construccion.contenido }
        alt={ construccion.alt }
        sectionID="pnif-construccion-section"
      />
      <AspectosDestacados
        title={ "Aspectos destacados de la política" }
        sectionID={ "aspectos-section" }
        { ...{ aspectos } } />
      <ImageSection
        title={ estructura.titulo }
        image={ estructura.image }
        alt={ estructura.alt }
        sectionID="pnif-estructura-section"
      />
      <Beneficios />
      <Allies sectionID="allies-section" { ...{ sponsors } } />
      <TextImageSection
        reversed
        imageBG={ theme.palette.extras.yellow }
        image={ vinculacion.image }
        title="Vinculación"
        content={ vinculacion.contenido }
        alt={ vinculacion.alt }
        sectionID="pnif-vinculacion-section"
      />
    </Layout>
  )
}

export default PNIF

const getSectionData = (arr: any) =>
  arr.edges.map(({ node }: any) => ({
    titulo: node.titulo,
    contenido: node.contenido.raw
  }))[0]
