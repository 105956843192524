import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  root: {

    [theme.breakpoints.up("md")]: {}
  },

  beneficiosList: {
    flexDirection: "column",
    padding: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 4)
    }
  },

  beneficiosItem: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
    borderTopLeftRadius: theme.roundness * 6,
    borderTopRightRadius: theme.roundness * 6,
    borderBottomLeftRadius: theme.roundness * 15,
    borderBottomRightRadius: theme.roundness,
    overflow: "hidden",
    flexDirection: "column"
  },

  beneficiosItemTitle: {
    margin: theme.spacing(0, -2),
    padding: theme.spacing(0.5, 4),
    "& h6": {
      color: theme.palette.text.lightText,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightBold
    }
  },

  beneficiosItemContent: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },

  beneficiosItemContentIconContainer: {
    padding: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0)
    }
  },

  iconCircle: {
    color: theme.palette.text.lightText,
    fontSize: theme.typography.h2.fontSize,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    padding: theme.spacing(2)
  },

  bulletContainer: {
    "& ul": {
      "& li": {
        margin: theme.spacing(1, 0)
      }
    }

  }
}))
