import { Grid, Tooltip } from "@material-ui/core"
import { Divider, Typography } from "@material-ui/core/"
import * as React from "react"
import { cardsText } from "../../../data/diagnostico"
import { SectionContainer, SectionText, SectionTitle } from "../../_layout"

import useStyles from "./styles"

interface IDiagnosticoSection {
  title: string
  upperText: string
  lowerText: string
  sectionID: string
}


const DiagnosticoSection: React.FC<IDiagnosticoSection> = ({
                                                             title,
                                                             sectionID,
                                                             upperText,
                                                             lowerText
                                                           }) => {
  const styles = useStyles()

  return (
    <SectionContainer { ...{ sectionID } }>
      <SectionTitle { ...{ title } } />

      <SectionText text={ upperText } />
      <SectionText text={ lowerText } />


      <Grid container className={ styles.treeContainer }>

        <Grid container className={ styles.upperCards }>
          {
            cardsText.upperCards.map((card) => (
              <Grid key={ card.id } container direction={ "column" }>
                <Grid item className={ styles.longCard } style={ { backgroundColor: card.color } }>
                  <Typography>{ card.text }</Typography>
                </Grid>
                <Grid item container>
                  <Grid item xs className={ styles.verticalConnector } />
                  <Grid item xs />
                </Grid>
              </Grid>
            ))
          }
        </Grid>

        <Grid container className={ styles.middleCards }>
          <Grid item container xs className={ styles.middleCardsLeftContainer }>
            <Grid item md={ 11 } className={ `${ styles.longCard } ${ styles.longCardSingle }` }>
              <Typography>{ cardsText.middleCard }</Typography>
            </Grid>

            <Grid item md={ 1 } container direction={ "column" }>
              <Grid item xs className={ styles.horizontalConnector } />
              <Grid item xs />
            </Grid>
          </Grid>

          <Grid item md className={ styles.middleCardsRightContainer } />
        </Grid>

        <Grid container className={ styles.lowerCards }>
          {
            cardsText.lowerCards.map((card, index) => (
              <Grid
                container
                item
                md
                key={ card.id }
                direction={ "column" }
              >
                <Grid item container>
                  <Grid
                    item xs
                    className={ `${ styles.verticalConnector } ${ index !== 0 && styles.topConnector }` }
                  />
                  <Grid item xs className={ index !== cardsText.lowerCards.length - 1 ? styles.topConnector : '' } />
                </Grid>

                <Grid item container className={ styles.tallCardContainer }>
                  <Tooltip title={ card.tootltip.split("\n").map(e => <p>{ e }</p>) }>
                    <Grid
                      item
                      container
                      className={ styles.tallCard }
                      style={ { backgroundColor: card.color } }
                    >
                      <Grid item>
                        <Divider className={ styles.divider } />
                      </Grid>

                      <Grid item xs>
                        <Typography>{ card.id }</Typography>
                        <Typography>{ card.text }</Typography>
                      </Grid>

                      <Grid item>
                        <Divider className={ styles.divider } />
                      </Grid>
                    </Grid>
                  </Tooltip>
                </Grid>

              </Grid>
            ))
          }
        </Grid>

        <Grid container className={ styles.instructionsContainer }>
          <Typography>Pasa el ratón sobre el recuadro para conocer más.</Typography>
        </Grid>

      </Grid>

    </SectionContainer>
  )
}

export default DiagnosticoSection
